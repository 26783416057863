<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Group</label>
                          <label style="color: red !important">*</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Group"
                          >
                            <v-select
                              v-model="group"
                              placeholder="None"
                              label="name"
                              :options="groupOptions"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
                <b-form-group class="resButtons mt-2">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-b-tooltip.hover.v-success
                      title="Print"
                      variant="gradient-success"
                      class="ml-2 btn-icon"
                      @click="printData"
                    >
                      <feather-icon icon="PrinterIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.v-success
                      title="Pdf"
                      variant="gradient-success"
                      class="ml-2 btn-icon"
                      @click="pdfData"
                    >
                      <feather-icon icon="DownloadIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.v-success
                      title="Excel"
                      variant="gradient-success"
                      class="ml-2 btn-icon"
                      @click="clickExcel"
                    >
                      <!-- @click="excelData" -->
                      <feather-icon icon="FileTextIcon" />
                    </b-button>
                  </div>
                </b-form-group>
                <b-row>
                  <b-col md="12" class="mt-2">
                    <div>
                      <table
                        class="table table-bordered"
                        id="printTable"
                        style="color: black"
                        border="1"
                      >
                        <thead class="text">
                          <tr>
                            <th>Group</th>
                            <th>Name</th>
                            <th colspan="2">Opening Balance</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th colspan="2">Closing Balance</th>
                          </tr>
                          <tr>
                            <th></th>
                            <th></th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th></th>
                            <th></th>
                            <th>Debit</th>
                            <th>Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in data" :key="item.id">
                            <td>{{ item.groups }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.ob_debit }}</td>
                            <td>{{ item.ob_credit }}</td>
                            <td>{{ item.debit }}</td>
                            <td>{{ item.credit }}</td>
                            <td>{{ item.cb_debit }}</td>
                            <td>{{ item.cb_credit }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-code>
              <!-- <report-table :data="data" :columns="fields" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import exportFromJSON from "export-from-json";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  VBTooltip,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
  },
  data() {
    return {
      data: "",
      startdate: "",
      enddate: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      t_ob_cr: 0,
      t_ob_dr: 0,
      t_cb_cr: 0,
      t_cb_dr: 0,
      t_dr: 0,
      t_cr: 0,
      groupOptions: [],
      group: "",
      fields: [
        {
          field: "id",
          label: "S.R. No",
          hidden: true,
        },
        {
          field: "groups",
          label: "Group",
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "amount",
          label: "Amount",
        },
        {
          field: "closingbalance",
          label: "Closing Balance",
        },
      ],
    };
  },
  mounted() {
    this.Group();
  },

  methods: {
    async Group() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/chartofaccount`,
      }).then((response) => {
        this.groupOptions.push({name:'All',id:'all'})
        this.groupOptions = [...this.groupOptions,...response.data.data.data];
      });
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      this.t_ob_cr = 0;
      this.t_ob_dr = 0;
      this.t_cb_cr = 0;
      this.t_cb_dr = 0;
      this.t_dr = 0;
      this.t_cr = 0;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const data = {
            startdate: this.startdate,
            enddate: this.enddate,
            gid: this.group.id,
          };
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/trialbalance`,
          }).then((response) => {
            this.data = response.data.data;
            this.data.map((item) => {
              item.ob_credit = 0;
              item.ob_debit = 0;
              item.cb_credit = 0;
              item.cb_debit = 0;

              this.t_dr += item.debit;
              this.t_cr += item.credit;
              item.groups = item.groups?item.groups.name:'';
              if (item.op_amount_crdr == "Dr.") {
                item.ob_debit = item.op;
                this.t_ob_dr += item.op;
              } else {
                item.ob_credit = item.op;
                this.t_ob_cr += item.op;
              }

              let amount =
                item.ob_credit - item.ob_debit + item.credit - item.debit;
              if (amount < 0) {
                item.cb_debit = (amount * -1).toFixed(2);
                this.t_cb_dr += parseFloat(amount * -1);
              } else {
                item.cb_credit = amount.toFixed(2);
                this.t_cb_cr += parseFloat(item.cb_credit);
              }
            });
            const obj = {
              group: "",
              name: "Total",
              ob_debit: this.t_ob_dr.toFixed(2),
              ob_credit: this.t_ob_cr.toFixed(2),
              debit: this.t_dr.toFixed(2),
              credit: this.t_cr.toFixed(2),
              cb_credit: this.t_cb_cr.toFixed(2),
              cb_debit: this.t_cb_dr.toFixed(2),
            };
            this.data.push(obj);
            this.intervalID = setInterval(this.myCallback, 500);
          });
        }
      });
    },
    myCallback() {
      document.getElementsByTagName("tr")[
        document.getElementsByTagName("tr").length - 1
      ].className = "font-weight-bolder";
      clearInterval(this.intervalID);
      this.$forceUpdate();
    },
    pdfData() {
      const data = document.getElementById("printTable");
      html2canvas(data).then((canvas) => {
        const fileWidth = 208;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const fileuri = canvas.toDataURL("image/png");
        const PDF = new jsPDF("p", "mm", "a4");
        const position = 0;
        PDF.addTable(fileuri, "PNG", 0, position, fileWidth, fileHeight);
        PDF.addFont(data);
        PDF.save("angular-demo.pdf");
      });
    },
    printData() {
      const divToPrint = document.getElementById("printTable");
      const newWin = window.open();
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    clickExcel() {
      this.data1 = [];
      this.data.map((data) => {
        const data1 = {};
        this.fields.map((col) => {
          if (data.hasOwnProperty(col.field)) {
            data1[col.label] = data[col.field];
          }
        });
        this.data1.push(data1);
      });
      const fileName = "exported-data";
      const data = this.data1;
      const fileExportType = null;
      const exportType = exportFromJSON.types[fileExportType || "xls"];
      exportFromJSON({ data, fileName, exportType });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
